import React from "react"
import { graphql } from "gatsby"

import Root from "../components/Root"
import Meta from "../components/Meta"
import GameHero from "../components/GameHero"
import GameIntro from "../components/GameIntro"
import GameFooter from "../components/GameFooter"
import Blades from "../components/Blades"

export const query = graphql`
  query GetGame($id: String) {
    posts: allDatoCmsPost(
      sort: { order: DESC, fields: meta___createdAt }
      filter: { game: { id: { eq: $id } } }
    ) {
      edges {
        node {
          title
          slug
          heroImage {
            mobile: fluid(
              imgixParams: { auto: "format", w: "360", h: "220", fit: "crop" }
            ) {
              base64
              height
              width
              src
              srcSet
              aspectRatio
            }
          }
          excerpt
          meta {
            createdAt
          }
        }
      }
    }
    game: datoCmsGame(id: { eq: $id }) {
      id
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      title
      tagline
      genre
      primaryColour {
        hex
      }
      secondaryColour {
        hex
      }
      purchaseLinks {
        name
        link
      }
      platforms {
        name
        lightLogo {
          url
        }
        darkLogo {
          url
        }
      }
      ratings {
        name
        icon {
          url
          fluid(imgixParams: { auto: "format" }) {
            base64
            height
            width
            src
            srcSet
            aspectRatio
          }
        }
      }
      copyrightImages {
        url
      }
      copyright
      releaseDate
      releaseDateText
      heading
      buyNowText
      buyNowTextColour
      body
      primaryColour {
        hex
      }
      heroImage {
        lowRes: fluid(
          imgixParams: { auto: "format", w: "768", h: "400", fit: "crop" }
        ) {
          base64
          height
          width
          src
          srcSet
          aspectRatio
        }
        highRes: fluid(
          imgixParams: { auto: "format", w: "1920", h: "1080", fit: "crop" }
        ) {
          base64
          height
          width
          src
          srcSet
          aspectRatio
        }
      }
      logo {
        fluid {
          base64
          height
          width
          src
          srcSet
          aspectRatio
        }
      }
      blades {
        ... on DatoCmsVideoHero {
          ...VideoHero
        }
        ... on DatoCmsVideoWithText {
          ...VideoWithText
        }
        ... on DatoCmsFeaturedGame {
          ...FeaturedGame
        }
        ... on DatoCmsGamesCarousel {
          ...GamesCarousel
        }
        ... on DatoCmsLatestNews {
          ...LatestNews
        }
        ... on DatoCmsCareersSpotlight {
          ...CareersSpotlight
        }
        ... on DatoCmsCareersArchive {
          ...CareersArchive
        }
        ... on DatoCmsImageHero {
          ...ImageHero
        }
        ... on DatoCmsCardsWithAnimation {
          ...CardsWithAnimation
        }
        ... on DatoCmsImageWithText {
          ...ImageWithText
        }
        ... on DatoCmsStudioShowcase {
          ...StudioShowcase
        }
        ... on DatoCmsLogoGrid {
          ...LogoGrid
        }
        ... on DatoCmsAwardsShowcase {
          ...AwardsShowcase
        }
        ... on DatoCmsFeatured {
          ...Featured
        }
        ... on DatoCmsGamesGrid {
          ...GamesGrid
        }
        ... on DatoCmsPreviousReleasesCarousel {
          ...PreviousReleasesCarousel
        }
        ... on DatoCmsPostSearchBar {
          ...PostSearchBar
        }
        ... on DatoCmsPostsGrid {
          ...PostsGrid
        }
        ... on DatoCmsStudioPerksList {
          ...StudioPerksList
        }
        ... on DatoCmsModalGrid {
          ...ModalGrid
        }
        ... on DatoCmsFeaturesPanel {
          ...FeaturesPanel
        }
        ... on DatoCmsMediaShowcase {
          ...MediaShowcase
        }
        ... on DatoCmsEditor {
          ...Editor
        }
      }
    }
  }
`

const GameTemplate = ({ data }) => {
  const posts = (data?.posts?.edges || []).map(({ node }) => ({ ...node }))
  const game = { posts, ...data?.game }
  const seo = game?.seo
  const blades = game?.blades

  return (
    <Root {...{ game }}>
      <Meta {...{ seo }} />
      <GameHero {...{ ...game }} />
      <GameIntro {...{ ...game }} />
      <Blades {...{ blades, game }} />
      <GameFooter {...{ ...game }} />
    </Root>
  )
}

export default GameTemplate
