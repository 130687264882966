import React from 'react'
import { getUnixTime, format } from 'date-fns'
import Img from 'gatsby-image'

import './style.scss'

const GameFooter = ({
    primaryColour,
    secondaryColour,
    genre,
    platforms = [],
    releaseDate,
    releaseDateText,
    ratings = [],
    purchaseLinks = [],
    copyrightImages = [],
    copyright,
    buyNowText,
}) => {
    const date = getUnixTime(new Date(releaseDate))
    const now = getUnixTime(new Date())

    return (
        <div className="game-footer py-4 md:py-8 xl:py-20 relative">
            <div className="container mx-auto px-10">
                <div className="grid md:grid-cols-14">
                    <div className="col-span-full xl:col-span-12 xl:col-start-2">
                        {(platforms.length > 0) && (
                            <div className="lg:px-12 xl:px-20 py-8 md:py-12">
                                <h3 className="mb-4 font-semibold text-lg text-boulder">Platform:</h3>
                                <div className="flex flex-wrap flex-col md:flex-row items-start md:items-center justify-start">
                                    {platforms.map(({ name, lightLogo }, i) => (
                                        <img key={i} src={lightLogo.url} alt={name} className={`w-48 max-w-xs max-h-8 ${(i === 0) ? 'mt-0' : 'mt-4 md:mt-0'} md:mr-12 md:mb-8`} />
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="lg:px-12 xl:px-20 border-t border-dashed border-white py-8 md:py-12">
                            <div className="grid grid-cols-2 md:grid-cols-none md:grid-flow-col">
                                <div className="flex flex-col">
                                    <h3 className="mb-4 font-semibold text-lg text-boulder">Genre:</h3>
                                    <p className="text-2xl text-white font-bold uppercase">{genre}</p>
                                </div>
                                <div className="col-span-full sm:col-span-1 flex flex-col mt-8 md:mt-0">
                                    <h3 className="mb-4 font-semibold text-lg text-boulder">Release:</h3>
                                    <p className="text-2xl text-white font-bold uppercase">{
                                        (releaseDateText && !date) ? releaseDateText : (date && date < now) ? 'Available now' : format(new Date(releaseDate), 'dd/MM/yyyy')
                                    }</p>
                                </div>
                                <div className="col-span-full sm:col-span-1 flex flex-col mt-8 md:mt-0">
                                    <h3 className="mb-4 font-semibold text-lg text-boulder">Age Rating:</h3>
                                    {(ratings.length > 0) ? (
                                        <div className="flex items-start">
                                            {ratings.map(({ name, icon }, i) => (
                                                <img key={i} src={icon.url} alt={name} className={`h-16 w-auto ${(i === 0) ? 'ml-0' : 'ml-4'}`} />
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="text-2xl text-white font-bold uppercase">N/A</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="lg:px-12 xl:px-20 border-t border-dashed border-white py-8 md:py-12 text-center">
                            {(purchaseLinks.length > 0) && (
                                <>
                                    <p className="text-2xl text-white font-bold uppercase">{buyNowText || 'Buy Now'}</p>
                                    <div className="mt-8 flex flex-col md:flex-row items-center justify-center">
                                        {purchaseLinks.map(({ name, link }, i) => (
                                            <a
                                                key={i}
                                                href={link}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                className={`${(i === 0) ? 'ml-0' : 'mt-4 md:mt-0 md:ml-4'} game-footer__purchase-button inline-flex items-center justify-center w-full md:w-auto py-2 px-6 md:px-10 text-lg text-blue-dark font-bold uppercase cursor-pointer outline-none`}
                                                style={{ backgroundColor: primaryColour?.hex, '--game-colour': secondaryColour?.hex }}
                                            >{name}</a>
                                        ))}
                                    </div>
                                </>
                            )}
                            {(copyrightImages.length > 0) && (
                                <div className="mt-8 md:mt-12 flex flex-col md:flex-row items-center justify-center text-center">
                                    {copyrightImages.map(({ url }, i) => (
                                        <div key={i} className={`flex items-center justify-center ${(i === 0) ? 'mt-0' : 'mt-8 md:ml-12'} md:mt-0`}>
                                            <img src={url} className="w-52 lg:w-72 max-w-sm max-h-60" />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <p className="mx-auto max-w-lg mt-8 md:mt-12 text-white">{copyright}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameFooter
