import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

const GameHero = ({
    title,
    logo,
    heroImage,
}) => (
    <div className="relative bg-black">
        <BackgroundImage
            tag="background"
            className="w-full h-full absolute top-0 left-0 bg-black bg-cover bg-center bg-no-repeat z-0"
            fluid={[
                heroImage.lowRes,
                {
                    ...heroImage.highRes,
                    media: '(min-width: 768px)',
                },
            ]}
            style={{ position: null }}
        />
        <div className="py-32 md:pt-48 relative">
            <div className="w-full h-full absolute top-0 left-0 bg-black opacity-20 z-10"></div>
            <div className="container mx-auto px-10">
                <div className="grid grid-cols-14 relative z-20">
                    <h1 className="hidden"><ReactMarkdown children={title} /></h1>
                    <Img fluid={logo.fluid} className="w-full col-span-12 col-start-2 lg:col-span-8 lg:col-start-4 xl:col-span-6 xl:col-start-5 select-none" />
                </div>
            </div>
        </div>
    </div>
)

export default GameHero
