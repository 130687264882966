import React from 'react'
import ReactMarkdown from 'react-markdown'

import './style.scss'

const GameIntro = ({
    tagline,
    heading,
    body,
    purchaseLinks = [],
    primaryColour,
    buyNowText,
    buyNowTextColour = 'Black',
}) => {
    return (
        <div className="game-intro py-12 md:py-16 xl:py-28 bg-white relative overflow-hidden">
            <div className="container mx-auto px-10 relative z-10">
                <div className="grid xl:grid-cols-14">
                    <div className="xl:col-span-12 xl:col-start-2">
                        <div className="grid md:grid-cols-5 md:gap-20">
                            <div className="md:col-span-2 md:border-r md:border-dashed md:border-aldo">
                                <h2 className="game-styled-heading max-w-xs mb-8 md:mb-0 line-styled-heading max-w-lg text-4xl md:text-5xl lg:text-6xl text-black font-extrabold uppercase" style={{ color: primaryColour?.hex }}><ReactMarkdown children={tagline} /></h2>
                            </div>
                            <div className="md:col-span-3">
                                <h3 className="mb-4 md:mb-8 text-xl font-light"><ReactMarkdown children={heading} /></h3>
                                <div className="wysiwyg"><ReactMarkdown children={body} /></div>
                                {(purchaseLinks.length > 0) && (
                                    <div className="mt-8 md:mt-12">
                                        <p className="text-2xl text-black font-bold uppercase">{buyNowText || 'Buy Now'}:</p>
                                        <div className="mt-4 lg:mt-8 flex flex-col lg:flex-row flex-wrap lg:items-center">
                                            {purchaseLinks.map(({ name, link }, i) => (
                                                <a
                                                    key={i}
                                                    href={link}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    className={`game-intro__button mt-4 lg:mt-0 lg:mr-4 lg:mb-4 inline-flex items-center justify-center w-full md:w-auto py-2 px-6 md:px-10 text-lg text-${buyNowTextColour === 'White' ? 'white' : 'black'} font-bold uppercase cursor-pointer outline-none hover:text-white transition duration-200`}
                                                    style={{ backgroundColor: primaryColour.hex }}
                                                >{name}</a>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameIntro
